<template>
    <b-form-group>
        <label>Tipos</label>
        <div id="type-multi-selector">
            <multiselect
                id="types"
                label="label"
                track-by="value"
                v-model="types"
                :multiple="true"
                :options="typeOptions"
                :showLabels="false"
                :searchable="false"
                :allowEmpty="true"
                :close-on-select="false"
                placeholder="Selecionar"
                class="with-border"
            >
                <template slot="caret">
                    <div class="chevron">
                        <ChevronDown />
                    </div>
                </template>
                <template #selection="{ values }">
                    <div v-if="types.length" class="selection">
                        <Ellipsis>{{ values.map(option => option.label).join(', ') }}</Ellipsis>
                    </div>
                </template>
                <template #option="{ option }">
                    <div class="option">
                        <Check 
                            :value="types.some(type => type.value === option.value)"
                            :label="option.label"
                        />
                        <div class="mask"></div>
                    </div>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
                <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
        </div>
    </b-form-group>
</template>
<script>
import * as ITEM from '@items/utils/constants'

export default {
    props: {
        groupType: String,
        value: Array,
        grouped: Boolean
    },
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        Ellipsis: () => import('@/components/General/Ellipsis'),
        Check: () => import('@/components/General/Check')
    },
    data() {
        const excludes = []
        if (this.grouped) {
            excludes.push(ITEM.ITEM_TYPE_APPOINTMENT)
            excludes.push(ITEM.ITEM_TYPE_EXAM)
            excludes.push(ITEM.ITEM_TYPE_SURGICAL)
            excludes.push(ITEM.ITEM_TYPE_RETURN)
            excludes.push(ITEM.ITEM_TYPE_TELEMEDICINE)
        }

        return {
            types: [],
            typeOptions: ITEM.ITEM_TYPE_OPTIONS
                .filter(option => ITEM.getByTypesByGroup(this.groupType).includes(option.value))
                .filter(option => !excludes.includes(option.value))
                .map(option => ({
                    ...option,
                    $isDisabled: ITEM.notImplementedTypes.includes(option.value)
                })           
            ),
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                console.log(this.typeOptions, 'bb')

                this.type = this.typeOptions.find(option => option.value === value)
                this.typeOptions.sort((a, b) => {
                    if (a?.label > b?.label) return 1;
                    else if (a?.label < b?.label) return -1;
                    else return 0
                })
                console.log(this.typeOptions, 'aa');

            }
        },
        types() {
            this.$emit('input', this.types.map(type => type.value))
            this.$emit('select', this.types.map(type => type.value))
        }
    }
}
</script>
<style lang="scss">
.selection {
    width: 100%;
    padding-left: 12px;
    padding-right: 35px;
}
.option {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}
.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
</style>